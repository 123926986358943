form {
    .inner-icon {
        &.location-search {
            .icon {
                left: 15px;
                top: 12px;
            }
            input {
                padding-left: 35px;
            }
            &.big {
                .icon {
                    top: 18px;
                }
            }
        }
        
    }
    input {
        &.form-control {
            &.location {
                background-color: $bg-form-control;
                height: 45px;
                &.lg {
                    background-color: $white;
                    height: 54px;
                }
            }
        }
    }
    textarea {
        height: 100px !important;
    }
}

@media screen and (max-width: $breakpoint-medium) {
    form {
        .inner-icon {
            &.location-search {
                .icon {
                    left: 10px;
                    top: 12px;
                }
                input {
                    padding-left: 35px;
                }
                &.big {
                    .icon {
                        top: 18px;
                    }
                }
            }
            
        }
        input {
            &.form-control {
                &.location {
                    background-color: $bg-form-control;
                    height: 40px;
                    &.lg {
                        
                    }
                }
            }
        }
    }
}