.owl-carousel {
    &.owl-filters {
        .owl-stage-outer {
            width: 90%;
            margin: 0 auto;
        }
        position: relative;
        .owl-nav {
            position: absolute;
            top: 0;
            width: 100%;
            button {
                height: 36px;
                width: 36px;
                border: 1px solid $black-gray !important;
                border-radius: 100px !important;
                line-height: 0 !important;
                position: absolute;
                span {
                    font-size: $font-size-heading !important;
                }
                &.owl {
                    &-prev {
                        left: 0;
                    }
                    &-next {
                        right: 0;
                    }
                }
                &:hover {
                    background-color: $white;
                    color: $text-color;
                }
            }
            
        }
        .owl-item {
            .item {
                margin-right: 20px !important;
            }
        }
    }
    &.owl-rdgallery {
        width: 700px;
        height: 100%;
        .owl-stage-outer {
            height: 100%;
            .owl-stage {
                height: 100%;
            }
        }
        .owl-nav {
            position: absolute;
            top: 45%;
            width: 100%;
            button {
                font-size: 30px !important;
                width: 36px;
                height: 36px;
                background: $white !important;
                border-radius: 100px;
                position: absolute;;
                color: $text-dark !important;
                opacity: .4;
                span {
                    margin-top: -6px !important;
                    display: block;
                }
                &:hover {
                    background: $white !important;
                    color: $text-dark;
                    opacity: 1;
                }
                &.owl-prev {
                    left: 10px;
                }
                &.owl-next {
                    right: 10px;
                }
            }
        }
        .owl-dots {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            .owl-dot {
                span {
                    background: transparent;
                    border: 1px solid $white;
                    opacity: .4;
                }
                &.active {
                    span {
                        background: $black;
                        opacity: 1;
                    }
                }
            }
        }
        .owl-item {
            // background-color: $bg-color;
            border-radius: 17px;
            height: 100%;
            img {
                // height: 300px;
                width: auto;
                margin: 0 auto;
                height: 100%;
            }
        }
    }
}
@media screen and (max-width: $breakpoint-medium) {
    .owl-carousel {
        &.owl-rdgallery {
            height: auto;
            position: relative !important;
            width: 100%;
            .owl-stage-outer {
                height: auto;
                .owl-stage {
                    height: 280px;
                    background-color: $black-gray;
                }
            }
        }
    }
}
