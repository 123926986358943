// Define breakpoints for responsive design
$breakpoint-small: 576px; // Small screens
$breakpoint-medium: 768px; // Medium screens
$breakpoint-large: 992px; // Large screens

// Define base font size
$base-font-size: 14px; // Base font size

// Define font size variables for different elements and breakpoints
$font-size-heading: 24px; // Font size for headings
$font-size-subheading: 20px; // Font size for subheadings
$font-size-paragraph: 18px; // Font size for paragraphs
$font-size-small-paragraph: 16px; // Font size for paragraphs
$font-size-small-text: 14px; // Font size for small text
$font-size-button: 16px; // Font size for buttons
$font-size-small-button: 14px; // Font size for buttons
$font-size-x-small-button: 12px; // Font size for buttons

// Responsive font sizes using media queries
body {
  font-size: $base-font-size; // Base font size
}

@media screen and (min-width: $breakpoint-small) {
  $font-size-heading: 28px;
  $font-size-subheading: 24px;
  $font-size-paragraph: 20px;
  $font-size-small-text: 16px;
  $font-size-button: 18px;
}

@media screen and (min-width: $breakpoint-medium) {
  $font-size-heading: 32px;
  $font-size-subheading: 28px;
  $font-size-paragraph: 22px;
  $font-size-small-text: 18px;
  $font-size-button: 20px;
}

@media screen and (min-width: $breakpoint-large) {
  $font-size-heading: 36px;
  $font-size-subheading: 32px;
  $font-size-paragraph: 24px;
  $font-size-small-text: 20px;
  $font-size-button: 22px;
}

// You can continue to define more font sizes and styles as needed for your project


// Primary colors
// $primary-color: #f14b5b; // Main brand color
$primary-color: #008080; // Main brand color
$primary-color-light: #FEEDEF;
$accent-color: #FFA500;


$secondary-color: #006666; // Secondary color
$success-color: #28a745; // Success or positive action color
$danger-color: #dc3545; // Danger or error color
$warning-color: #ffc107; // Warning or alert color
$info-color: #17a2b8; // Information or notification color

// Background colors
$bg-color: #f8f9fa; // Default background color
$bg-secondary: #e2e3e5; // Secondary background color
$bg-form-control: #EFF0F2;
$bg-dark: #343a40; // Dark background color


$white: #ffffff; // White background color

// Transparent
$white-t-6:rgba(255, 255, 255, 0.6); // White background color
$white-t-5:rgba(255, 255, 255, 0.5); // White background color

// Text colors
$text-color: #333; // Default text color
$text-light: #777; // Light text color
$text-muted: #aaa; // Muted or faint text color
$text-dark: #000; // Dark text color
$text-white: #fff; // White text color

// Border colors
$border-color: #e2e3e5; // Default border color
$border-light: #eee; // Light border color
$border-dark: #666; // Dark border color

// Custom colors
$custom-color-1: #ff5722; // Custom color 1
$custom-color-2: #4caf50; // Custom color 2
$custom-color-3: #f44336; // Custom color 3
$custom-color-4: #9c27b0; // Custom color 4
$custom-color-5: #2196f3; // Custom color 5

// Use these variables in your styles
// Black shades
// $black: #000; // Pure black
$black-lightest: #333; // Lightest shade of black
$black-light: #666; // Light shade of black
$black-gray: #999; // Grayish black
$black: #111; // Darker shade of black
$black-dark: #444; // Dark shade of black
$black-darkest: #222; // Darkest shade of black

// Use these variables in your styles
$border-radius-circle: 100px;