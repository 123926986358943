hr {
    &.bg-light {
        border-top: 1px solid $border-color !important;
        opacity: 1;
    }
}
.rent {
    &-summary {
        background: $white;
        bottom: 0;
        left: 0;
        z-index: 2;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }
    &-cost {
        font-size: $font-size-paragraph
    }
    &-user {
        &-avatar {
            height: 48px;
            border-radius: 100px;
        }
        &-phone {
            font-size: $font-size-small-text;
            .icon {
                
            }
        }
    }
    &-meta {
        // font-size: $font-size-small-paragraph;
    } 
}
.count {
    width: 100px;
    height: 36px;
    border: 1px solid $border-color;
    border-radius: 10px;
    padding-top: 5px;
}

.sidebar {
    &-right {
        width: 280px;
        border-left: 1px solid $bg-secondary !important;
    }
}
.alert {
    font-size: $font-size-small-text;
}

.topbar {
    z-index: 2;
    .location-search {
        .icon {
            left: 15px;
            top: 12px;
        }
        input {
            padding-left: 35px;
            background-color: $bg-form-control;
        }
    }
}



.bg-blur {
    backdrop-filter: blur(100px) brightness(200%);
}

.location {
    &-list {
        max-height: 400px;
        overflow-y: scroll;
        z-index: 3;
    }
}

.content-section {
    &.profile {
        .uphoto {
            height: 120px;
            width: 120px;
            &.sm {
                height: 60px;
                width: 60px;
            }
        }
        .greetings {
            font-size: $font-size-subheading;
        }
    }
}

.toast-container {
    &.position-fixed {
        left: 20px;
        bottom: 20px;
    }
}
.idc-val {
    width: 100px;
}

.react-datepicker {
    &-wrapper  {
        width: 100%;
    }
    &__input-container {
        input {
            height: 58px;
        }
    }
    
}
.react-datepicker-popper[data-placement^="bottom"] {
    z-index: 3;
}

.imageWrapper {
    width: 150px;
    height: 150px;
    border: 1px dashed #00B2B2;
    padding: 10px;
    overflow: hidden;
    display: inline-block;
    img {
        width: 130px;
        height: auto;
    }
}