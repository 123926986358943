.card {
    &.rent {
        &-default {
            .card {
                &-title {
                    font-size: $base-font-size;
                }
                &-subtitle {
                    font-weight: 300;
                    color: $text-light;
                }
                &-text {
                    font-size: $font-size-small-text;
                }
            }
            .img-wrapper {
                border-radius: 13px;
                overflow: hidden;
                &::before {
                    content: '';
                    background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%); 
                    width: 100%;
                    height: 40px;
                    display: block;
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                }
            }
            .thumb {
                &-wrapper {
                    bottom: 10px;
                    right: 10px;
                    .thumb {
                        &-count {
                            font-size: $font-size-small-text;
                        }
                    }
                }
            }
        }
        &-posting-type {
            border: 1px solid $border-color;
            border-radius: 17px !important;
            &:hover {
                background-color: $bg-color;
            }
            .card {
                &-img {
                    &.icon {
                        height: 48px !important;
                        z-index: 1;
                    }
                }
                &-title {
                    font-size: $font-size-paragraph;
                    color: $primary-color;
                    font-weight: 600;
                }
                &-subtitle {
                    font-weight: 400;
                    color: $text-dark;
                }
                &-bg {
                    left: -20px;
                    height: 150%;
                    z-index: 0;
                }
            }
        }
    }
    &.facilities {
        border-radius: $border-radius-circle;
        background: $white-t-5;
        .card-img-top {
            height: 18px;
            width: 18px;
        }
        .card-text {
            font-size: $font-size-small-text;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    .card {
        &.facilities {
            width: auto;
            float: left;
            .card-body {
                padding: 8px 10px;
            }
            .card-img-top {
                height: 18px;
                width: 18px;
            }
        }
        &.rent-posting-type {
            .card {
                &-img {
                    &.icon {
                        height: 30px !important;
                        z-index: 1;
                    }
                }
                &-bg {
                    height: 130%;
                }
                &-title {
                    font-size: $font-size-heading - 10;
                }
                &-subtitle {
                    font-size: $base-font-size - 2;
                }
            }
        }
        
    }
}