.rent-details {
    background-color: rgba($color: #ffffff, $alpha: 1);
}

@media screen and (max-width: $breakpoint-medium) {
    .action-control {
        button {
            width: 100%;
        }
    }
}
