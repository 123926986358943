.modal {
    &.bg-blur{
        .modal-dialog {
            backdrop-filter: blur(100px) brightness(200%);
            .modal-content {
                background-color: transparent;
                .modal-header {
                    z-index: 1;
                    &.rent-details {
                        z-index: 1;
                    }
                    .btn-close {
                        position: absolute;
                    }
                }
                .modal-body {
                    
                }
            }
        }    
    }
    &-location {
        .modal-content {
            border: 0;
            background-color: transparent !important;
        }
        .modal-dialog {
            
        }
    }
    
}
body {
    background-color: transparent !important;
}