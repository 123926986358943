.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { font-weight: 500; }
h1 { font-size: $font-size-heading !important; }
.fs-12 { font-size: 12px; }
.fs-14 { font-size: 14px; }
.fs-18 { font-size: 18px !important; }
.fs-20 { font-size: 20px; }
.fs-24 { font-size: 24px; }
.fw-md { font-weight: 500; }
.fw-bold { font-weight: 600 !important; }
.text-light { color: $text-light !important; }
.mb-lg { margin-bottom: 100px; }
.pre-line {white-space: pre-line;}

@media screen and (max-width: $breakpoint-medium) {
    h1 { font-size: $font-size-heading - 6 !important; }
    .mt-m-big {
        margin-top: 60px;
    }
}
