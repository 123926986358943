.btn {
    padding: 12px 15px;
    border-radius: 12px;
    &-primary {
        background-color: $secondary-color !important;
        border: 1px solid $secondary-color !important;
        padding: 12px 20px;
        border-radius: 10px;
        font-weight: 500;
        color: $white;
        text-decoration: none;
    }
    &-dark {
        background-color: $black-lightest !important;
        border: 1px solid $black-lightest !important;
        border-radius: 10px;
        font-weight: 500;
        color: $white;
        text-decoration: none;
        &:hover {
            background-color: $black !important;
            border: 1px solid $black !important;
        }
    }
    &-card {
        text-decoration: none;
        color: $text-color;
    }
    &-filter {
        font-size: $font-size-small-button !important;
        font-weight: 500 !important;
        color: $text-color !important;
        opacity: .6;
        border-radius: 0 !important;
        white-space: nowrap;
        .icon {
            display: block;
            height: 30px;
        }
        &.active {
            border-bottom: 2px solid $black-lightest !important;
            opacity: 1;
        }
        &:hover {
            border-bottom: 2px solid $black-gray !important;
            opacity: 1;
        }
        &-main {
            font-size: $font-size-small-button !important;
            font-weight: 500 !important;
            padding: 10px !important;
            border: 1px solid $border-dark !important;
            background-color: $white;
            &.active {
                background-color: $white;
                border: 2px solid $black !important;
                .icon {
                    path {
                        fill: $white;
                    }
                }
            }
            .counts {
                right: -7px;
                top: -7px;
                background: $black;
                border-radius: 20px;
                border: 2px solid $white;
            }
            .icon {
                height: 16px;
                vertical-align: sub;
            }
        }
        &-outline {
            background-color: $white;
            border: 1px solid $border-light;
            font-size: $font-size-small-button;
            padding: 10px 12px;
            &:hover {
                background: $white !important;
                border: 1px solid $primary-color !important;
            }
            &.active {
                background-color: $primary-color !important;
                color: $white !important;
                border: 1px solid $primary-color !important;
            }
            &:active{
                background-color: $white !important;
                color: $black-darkest !important;
                border: 1px solid $black-lightest !important;
            }
        }
    }
    &-light {
        &:hover, &:focus {
            background-color: $white;
        }
        &.active {
            background-color: $primary-color;
            color: $white;
            border: 1px solid $primary-color;
        }
    }
    &-sm {
        padding: 10px 13px;
        font-size: $font-size-x-small-button;
    }
    &-idnc {
        width: 36px;
        height: 36px;
        padding: 0;
    }
    &-rounded {
        border-radius: 50px;
    }
    &-link {
        color: $black;
        &.no {
            &-underline {
                text-decoration: none;
            }
        }
    }
    &-close {
        vertical-align: inherit;
        opacity: 1;
    }
    &-pcls {
        left: 20px;
        top: 20px;
        width: auto;
    }
    &-idc {
        height: 36px;
        width: 36px;
        padding: 0;
    }
    &-toggle-group {
        .btn {
            color: $text-light;
            border: 1px solid $border-light !important;
            width: 79px;
            .icon {
                height: 14px;
                width: 14px;
                display: none;
            }
            &.active {
                background-color: $secondary-color !important;
                border: 1px solid $secondary-color !important;
                color: $white;
                .icon {
                    display: inline-block;
                }
            }
            
        }
    }
    &-delete {
        &-icon {
            right: 15px;
            top: 15px;
            padding: 5px 8px;
        }
    }
}
@media screen and (max-width: $breakpoint-medium) {
    .btn-filter-main {
        height: 40px;
        width: 40px;
        border-radius: 100px;
        .icon {
            margin-top: 2px;
        }
    }
    .btn {
        &-filter {
            &-outline {
                padding: 10px 12px;
                margin-bottom: 10px;
            }
        }
        &-pcls {
            // width: 100%;
            text-align: left;
            background: $white;
            z-index: 3;
            top: 0;
            left: 0;
            padding: 12px !important;
            .icon {
                height: 30px;
            }
        }
        &-rent-post {
            position: fixed;
            bottom: 55px;
            left: 0;
            right: 0;
            padding: 5px 8px;
            font-size: $font-size-small-button - 2;
            width: fit-content;
            margin: 0 auto;
        }
    }
}
