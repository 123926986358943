header {
    border-right: 1px solid $bg-secondary !important;
    height: 100%;
    width: 280px;
    position: fixed;
    padding-right: 40px !important;
}
.navbar {
    &-top {
        .nav {
            &-link {
                font-size: $font-size-button;
                &.active {
                    background-color: $primary-color-light;
                    border-radius: 30px;
                    color: $primary-color;
                    .icon {
                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }

        .navbar {
            &-brand {
                .logo {
                    height: 26px;
                }
            }
        }
        .user-avatar {
            height: 36px;
            width: 36px;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
    header {
        width: 100%;
        position: fixed;
        z-index: 1;
        background-color: $white;
        padding: 0px !important;
        left: 0;
        right: 0;
        height: 50px;
        border-top: 1px solid $border-color;
        bottom: 0;
        .navbar-nav {
            .nav-link {
                display: inline-block;
                width: 33.33%;
                font-size: $font-size-x-small-button;
                text-align: center;
                line-height: 12px;
                .icon {
                    margin: 0 auto;
                    display: block;
                    height: 20px;
                    margin-bottom: 2px;
                }
                &.active {
                    background-color: $white;
                }
            }
        }
    }
    .topbar-mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: 12px 7px;
    }
    .sidebar-right {
        position: fixed !important;
        z-index: 1;
        width: 100%;
        left: 0;
        right: 0;
        top: 0px;
        background-color: $white;
        height: 60px !important;
        padding: 10px 10px !important;
        border: 0 !important;
    }
}